import React from 'react'
import './ProductCard.scss'
import { NavLink } from 'react-router-dom'

type Props = {
  id: string
  schoolId: string
  name: string
  price: string
  image: string
}

function ProductCard({ id, schoolId, name, price, image }: Props) {
  return (
    <NavLink to={`/item/${schoolId}/${id}`} className="product_card_vert">
      <div className="image-container">
        <img src={image} alt="product" />
      </div>
      <div className="product_card_vert-content">
        <h1 className="product_card_vert-content_title">{name}</h1>
        <h6 className="product_card_vert-content_price">£ {price}</h6>
      </div>
    </NavLink>
  )
}

export default ProductCard