import { Product } from '../types'
import { Request } from './api'

const RequestConfig = {
  create_setup_intent: (schoolId?: string) => {
    return {
      method: 'POST',
      url: `/api/payments/create-setup-intent`,
      contentType: 'application/json',
      e: 'Cannot create setup intent',
    }
  },
}

const createSetupIntent = async (productIds: string[]): Promise<{
  response: { clientSecret: string }
  responseStatusCode: number | undefined
}> => {
  return Request(RequestConfig.create_setup_intent(), { productIds })
}

export { createSetupIntent }