import React from 'react'
import BasketItem from './components/BasketItem/BasketItem';
import BasketSummary from './components/BasketSummary/BasketSummary';
import './Basket.scss'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { setBasket } from 'src/store/slices/basketSlice';

function Basket() {
  const { basketItems } = useSelector((state: RootState) => state.basket);
  const dispatch = useDispatch();

  const removeItem = (id: string) => {
    dispatch(setBasket(basketItems.filter((item) => item.id !== id)));
  };

  return (
    <div className="basket_page">
      <h1>Basket</h1>
      <div>
        {basketItems.map((item) => (
          <BasketItem key={item.id} item={item} onRemove={removeItem} />
        ))}
      </div>
      <BasketSummary items={basketItems} />
    </div>
  );
}

export default Basket