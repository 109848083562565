import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './Item.scss'
import { ImageCarousel, ItemSizes } from './components';
import { useDispatch } from 'react-redux';
import { addToBasket } from '../../store/slices/basketSlice';
import { Product } from 'src/common/types';
import { getProductById } from 'src/common/api/product';

type Props = {}

function Item({ }: Props) {
  const { itemId, schoolId } = useParams();
  const [product, setProduct] = useState<Product>()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSizeChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log(e.target)
  }

  const fetchProduct = async (itemId: string) => {
    try {
      const { response } = await getProductById(itemId)
      setProduct(response)
    } catch (error) {
      console.error(error)
    }
  }

  const handleAddToBasket = () => {
    if (product) {
      dispatch(addToBasket({
        id: '2',
        name: 'Blue Oxford Jacket',
        price: product.price,
        quantity: 1,
        size: 'M',
        imageSrc: product.images[0].image_url,
        imageAlt: product.images[0].alt_text,
        school: 'Baron Academy'
      }))
    }
  }

  const handleBuyNow = () => {
    if (product) {
      dispatch(addToBasket({
        id: '2',
        name: 'Blue Oxford Jacket',
        price: product.price,
        quantity: 1,
        size: 'M',
        imageSrc: product.images[0].image_url,
        imageAlt: product.images[0].alt_text,
        school: 'Baron Academy'
      }))
    }
    navigate('/checkout')
  }

  useEffect(() => {
    itemId && fetchProduct(itemId)
  }, [itemId])

  return (
    product ? (
      <div className='product-container flex-col lg:flex-row'>
        <ImageCarousel images={product.images} />
        <div className='flex flex-col gap-4 lg:w-2/4'>
          <div>
            <span className=' text-violet-600 font-semibold'>Baron Academy</span>
            <h1 className='text-3xl font-bold'>{product?.name}</h1>
          </div>
          <p className='text-gray-700'>
            Introducing a must-have addition to your wardrobe that embodies both timeless elegance and modern style. This versatile piece is thoughtfully designed to offer comfort, quality, and a touch of sophistication, making it a perfect fit for any occasion. Discover the perfect blend of fashion and function with this exceptional choice from our collection.
          </p>
          <h6 className='text-2xl font-semibold'>£ 108.00</h6>
          <ItemSizes {...{ itemId, handleSizeChange }} />
          <div className='flex flex-col lg:flex-row gap-6'>
            <button onClick={handleAddToBasket} className='bg-violet-800 text-white font-semibold py-3 px-16 rounded-xl h-full'>Add to Basket</button>
            <button onClick={handleBuyNow} className='bg-violet-800 text-white font-semibold py-3 px-16 rounded-xl h-full'>Buy now</button>
          </div>
        </div>
      </div>
    ) : (
      <div>loading</div>
    )
  )
}

export default Item