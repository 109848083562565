import * as React from 'react';
import './SignIn.scss'
import { config } from '../../../config';
import { GuestGuard } from '../../../components/Auth/GuestGuard';
import { Layout } from '../../../components/Auth/Layout';
import { SignInForm } from '../../../components/Auth/SignInForm';

export const metadata = { title: `Sign in | Auth | ${config.site.name}` };

export default function Page(): React.JSX.Element {
  console.log('sign in page')
  return (
    <Layout>
      <GuestGuard>
        <SignInForm />
      </GuestGuard>
    </Layout>
  );
}
