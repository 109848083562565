import React, { useEffect, useRef } from 'react'
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, NavLink } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Controller, useForm } from 'react-hook-form';
import { z as zod } from 'zod';

import { paths } from '../../constants/paths';
import { authClient } from '../../lib/auth/client';
import { useUser } from '../../hooks';
import { TabBar, Tab } from '../TabBar/TabBar';
import StashupLogo from 'src/assets/icons/stashup-logo';

const schema = zod.object({
  email: zod.string().min(1, { message: 'Email is required' }).email(),
  password: zod.string().min(1, { message: 'Password is required' }),
});

type Values = zod.infer<typeof schema>;

const defaultValues = { email: 'hectorcampbell99@gmail.com', password: 'Secret1' } satisfies Values;

export function SignInForm(): React.JSX.Element {
  const navigate = useNavigate();
  const { checkSession } = useUser();
  const [showPassword, setShowPassword] = React.useState<boolean>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const submitButton = useRef<HTMLButtonElement | null>(null)

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Values>({ defaultValues, resolver: zodResolver(schema) });

  // Side effects for the login request loading status
  useEffect(() => {
    if (isLoading === true && submitButton.current) {
      submitButton.current.classList.add('loading')
    } else if (submitButton.current) {
      submitButton.current.classList.remove('loading')
    }
  }, [isLoading])

  const onSubmit = React.useCallback(
    async (values: Values): Promise<void> => {
      setIsLoading(true);

      const { error } = await authClient.signInWithPassword(values);

      if (error) {
        setError('root', { type: 'server', message: error });
        setIsLoading(false);
        return;
      }

      // Refresh the auth state
      await checkSession?.();

      // UserProvider, for this case, will not refresh the router
      // After refresh, GuestGuard will handle the redirect
      navigate(paths.dashboard.overview);
    },
    [checkSession, navigate, setError]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='signIn-form'>
      <div className="signIn-logo">
        <StashupLogo fill='#023047' strokeWidth={3} />
      </div>
      <h1>Welcome back!</h1>
      <p>Please enter your details to sign in</p>
      <TabBar callback={() => {}}>
        <Tab>Sign In</Tab>
        <Tab>Sign Up</Tab>
      </TabBar>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <FormControl error={Boolean(errors.email)}>
            <InputLabel>Email address</InputLabel>
            <OutlinedInput {...field} label="Email address" type="email" />
            {errors.email ? <FormHelperText>{errors.email.message}</FormHelperText> : null}
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <FormControl error={Boolean(errors.password)}>
            <InputLabel>Password</InputLabel>
            <OutlinedInput
              {...field}
              endAdornment={
                showPassword ? (
                  'eye'
                ) : (
                  'eye-off'
                )
              }
              label="Password"
              type={showPassword ? 'text' : 'password'}
            />
            {errors.password ? <FormHelperText>{errors.password.message}</FormHelperText> : null}
          </FormControl>
        )}
      />
      <div>
      </div>
      {errors.root ? <Alert color="error">{errors.root.message}</Alert> : null}
      <button className='submit' id='sign-in' type='submit' ref={submitButton}>
        <span>Sign In</span>
      </button>
      <div className='separator'><span>or continue with</span></div>
      <button className='submit apple' id='sign-in-apple'>Sign In with Apple</button>
      <button className='submit google' id='sign-in-google'>Sign In with Google</button>
      <NavLink to={paths.auth.resetPassword}>
        Forgot password?
      </NavLink>
      <p>Can't Login? <a href="#">Contact Us</a></p>
    </form>
  );
}
