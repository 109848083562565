import React from 'react';
import './BasketItem.scss'
import { BasketItemType } from 'src/common/types';

interface BasketItemProps {
  item: BasketItemType;
  onRemove: (id: string) => void;
}

function BasketItem({ item, onRemove }: BasketItemProps) {
  return (
    <div className="basket_page-item">
      <h2>{item.name}</h2>
      <p>Price: ${item.price.toFixed(2)}</p>
      <p>Quantity: {item.quantity}</p>
      <button onClick={() => onRemove(item.id)}>Remove</button>
    </div>
  );
};

export default BasketItem;