import React, { useState } from 'react'
import './TabBar.scss'

type TabProps = {
  children: React.ReactNode,
  onTabClick?: (tabValue: number) => void;
  isActive?: boolean;
  tabIndex?: number;
}

function Tab({ children, onTabClick, isActive, tabIndex }: TabProps) {
  return (
    <button
      className='TabBar-tab'
      type='button'
      onClick={() => onTabClick!(tabIndex!)}
    >
      {children}
    </button>
  )
}

type TabBarProps = {
  callback?: (tabValue: number) => void;
  children: React.ReactElement<TabProps>[];
}

const MAX_CHILDREN = 5;

function TabBar({ children, callback }: TabBarProps) {
  if (React.Children.count(children) > MAX_CHILDREN) {
    throw new Error(`TabBar can have a maximum of ${MAX_CHILDREN} children.`);
  }

  const [activeIndex, setActiveIndex] = useState(0)

  const handleTabClick = (tabValue: number) => {
    setActiveIndex(tabValue);
    callback && callback(tabValue);
  };

  const Tabs = React.Children.map(children, (child, childIndex) => {
    if (React.isValidElement(child)) {
      const { isActive, ...rest } = child.props;
      
      const tabProps = {
        isActive: childIndex === activeIndex,
        tabIndex: childIndex,
        onTabClick: handleTabClick,
        ...rest
      }

      return React.cloneElement(child, tabProps);
    }
    return child;
  });

  return (
    <div className='TabBar'>
      {Tabs}
      <div className={`TabBar-background position-${activeIndex}`}></div>
    </div>
  )
}

export { TabBar, Tab }