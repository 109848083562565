import React, { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
const apiHost = process.env.REACT_APP_API_URL
const apiScheme = process.env.REACT_APP_API_SCHEME

type Endpoint = {
  method: string
  url: string
  contentType?: string | undefined
  e: string | undefined
}

export const Request = async (
  endpoint: Endpoint,
  body?: any,
) => {
  let response
  let responseStatusCode

  const result = await fetch(`${apiScheme}://${apiHost}${endpoint.url}`, {
    method: endpoint.method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })

  responseStatusCode = result.status
  response = await result.json()

  return { response, responseStatusCode }
}