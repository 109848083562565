import React from 'react'
import './Header.scss'
import StashupLogo from 'src/assets/icons/stashup-logo'
import { NavLink } from 'react-router-dom'
import Basket from './components/Basket/Basket'

type Props = {
  navigationLinks: any
}

function Header({ navigationLinks }: Props) {
  return (
    <header className="header-main">
      <nav>
        <section>
          <div className="page-container">
            <div className="header-top">
              <div className="brand">
                <StashupLogo fill="#D2AC47" strokeWidth={2} />
                <h2>Stashup</h2>
              </div>
              <div className="header-features">
                <div className="search-bar">
                  <input type="text" placeholder="Search for schools" />
                  <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                </div>
                <NavLink to="/account">
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.4rem" viewBox="0 0 16 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>
                </NavLink>
                <Basket />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="page-container">
            <ul className="header-bottom">
              {navigationLinks.map((link: any, index: number) => (
                <li key={index}>
                  <NavLink to={link.path}>{link.title}</NavLink>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </nav>
    </header>
  )
}

export default Header