import { Avatar, Button, Divider, List, ListItem, ListItemContent, ListItemDecorator, Sheet, Typography } from '@mui/joy'
import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from 'src/contexts/AuthContext'

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useNavigate } from 'react-router-dom';

type Props = {}

function Orders({ }: Props) {
  // const [user] = useContext(AuthContext)!
  const navigate = useNavigate()

  const orders = [
    {
      logo: '/static/images/joy/logo.png',
      role: 'Frontend Developer',
      name: 'Joy Corporation',
      years: '2 years',
    },
    {
      logo: '/static/images/joy/logo.png',
      role: 'Backend Developer',
      name: 'Joy Corporation',
      years: '1 year',
    },
  ]

  return (
    <li
      onClick={() => navigate('/orders')}
      className="bg-white border rounded-md p-4 cursor-pointer list-none card"
    >
      <div className="flex gap-4">
        <div className="w-12 h-12 border rounded-full flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#D2AC47" className="bi bi-box-seam-fill" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003zM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461z" />
          </svg>
        </div>
        <div>
          <div className="text-md font-semibold">Your Orders</div>
          <div className="text-sm">Track, return, cancel orders</div>
        </div>
        <div className="ml-auto flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
          </svg>
        </div>
      </div>

    </li>
  )
}

export default Orders