import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent, Grid, Divider } from '@mui/material';
import { Order } from './types';
import './Orders.scss'

const mockOrders: Order[] = [
  {
    orderId: '1',
    orderDate: '2023-06-01',
    items: [
      { id: 1, name: 'Product A', price: 100, quantity: 2 },
      { id: 2, name: 'Product B', price: 50, quantity: 1 },
    ],
    totalAmount: 250,
    status: 'Delivered',
  },
  {
    orderId: '2',
    orderDate: '2023-05-15',
    items: [
      { id: 3, name: 'Product C', price: 75, quantity: 3 },
    ],
    totalAmount: 225,
    status: 'Shipped',
  },
  // Add more orders as needed
];

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    // Simulate fetching orders from an API
    // In a real app, you would fetch this data from a backend server
    // Here we're just using mock data
    setOrders(mockOrders);
  }, []);

  return (
    <div className="orders-page">
      <h1 className="text-3xl font-bold mb-5">My orders</h1>

      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Order #54879</h2>
          <span className="text-gray-600">Order placed March 22, 2021</span>
        </div>

        <div className="border-t border-gray-200 py-4">
          <div className="flex items-center mb-4">
            <img src="tumbler-image.jpg" alt="Nomad Tumbler" className="w-16 h-16 object-cover mr-4" />
            <div>
              <h3 className="font-semibold">Nomad Tumbler</h3>
              <p className="text-gray-600">$35.00</p>
              <p className="text-sm text-gray-500">This durable and portable insulated tumbler...</p>
            </div>
          </div>

          <div className="flex items-center">
            <img src="watch-image.jpg" alt="Minimalist Wristwatch" className="w-16 h-16 object-cover mr-4" />
            <div>
              <h3 className="font-semibold">Minimalist Wristwatch</h3>
              <p className="text-gray-600">$149.00</p>
              <p className="text-sm text-gray-500">This contemporary wristwatch has a clean...</p>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <h4 className="font-semibold mb-2">Order Status</h4>
          <div className="bg-gray-200 rounded-full h-2 mb-2">
            <div className="bg-blue-500 rounded-full h-2 w-1/2"></div>
          </div>
          <div className="flex justify-between text-sm text-gray-600">
            <span>Order placed</span>
            <span>Processing</span>
            <span>Shipped</span>
            <span>Delivered</span>
          </div>
        </div>

        <div className="mt-6 text-sm">
          <p><strong>Delivery address:</strong> Floyd Miles, 7363 Cynthia Pass, Toronto, ON N3Y 4H8</p>
          <p><strong>Total:</strong> $83.16</p>
        </div>
      </div>
    </div>
  );
};

export default Orders