import React from 'react'

type Props = {
  fill: string
  strokeWidth: number
  className?: string
}

function StashupLogo({ fill, strokeWidth, className }: Props) {
  return (
    <svg viewBox="0 0 36 39" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M30 19.6522L18.7385 24.6652C18.4979 24.7712 18.2367 24.8261 17.9723 24.8261C17.7079 24.8261 17.4467 24.7712 17.2062 24.6652L6 19.6522M30 25.7391L18.7385 30.7522C18.4979 30.8582 18.2367 30.913 17.9723 30.913C17.7079 30.913 17.4467 30.8582 17.2062 30.7522L6 25.7391M18.8707 18.8696C18.5959 18.9843 18.2995 19.0435 18 19.0435C17.7005 19.0435 17.4041 18.9843 17.1293 18.8696L6.57021 14.169C6.39875 14.0858 6.25466 13.9586 6.15398 13.8014C6.05331 13.6442 6 13.4633 6 13.2787C6 13.0941 6.05331 12.9131 6.15398 12.7559C6.25466 12.5988 6.39875 12.4715 6.57021 12.3884L17.1293 7.65212C17.4041 7.53744 17.7005 7.47826 18 7.47826C18.2995 7.47826 18.5959 7.53744 18.8707 7.65212L29.4298 12.3528C29.6013 12.4359 29.7453 12.5632 29.846 12.7203C29.9467 12.8775 30 13.0585 30 13.2431C30 13.4276 29.9467 13.6086 29.846 13.7658C29.7453 13.923 29.6013 14.0502 29.4298 14.1333L18.8707 18.8696ZM33.7265 8.71705C33.5954 8.52023 33.416 8.36909 33.2096 8.2815L18.4417 2.10227C18.3019 2.03482 18.1511 2 17.9986 2C17.8461 2 17.6953 2.03482 17.5556 2.10227L2.78762 8.39039C2.59252 8.44754 2.41432 8.56016 2.27075 8.71705C2.09882 8.94707 2.00327 9.23526 2 9.53369V29.4597C2.00319 29.7315 2.07992 29.9961 2.2203 30.2193C2.36068 30.4425 2.55828 30.614 2.78762 30.7119L17.5556 37H18.4417L33.2096 30.7119C33.439 30.614 33.6365 30.4425 33.7769 30.2193C33.9173 29.9961 33.994 29.7315 33.9972 29.4597V9.64257C34.018 9.30702 33.9211 8.97552 33.7265 8.71705Z" stroke={fill} stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default StashupLogo