import path from "path";

export const navigationLinks = [
  {
    title: 'Uniform',
    path: '/items',
  },
  {
    title: 'Boys',
    path: '/items',
  },
  {
    title: 'Girls',
    path: '/items',
  },
  {
    title: 'Schools',
    path: '/schools'
  },
  {
    title: 'Selling',
    path: '/selling'
  },
  {
    title: 'Sustainability',
    path: '/sustainability'
  },
]