import React from 'react';
import './BasketSummary.scss'
import { BasketItemType } from 'src/common/types';

interface BasketSummaryProps {
  items: BasketItemType[];
}

function BasketSummary({ items }: BasketSummaryProps) {
  const totalAmount = items.reduce((total, item) => total + item.price * item.quantity, 0);

  return (
    <div>
      <h2>Total: ${totalAmount.toFixed(2)}</h2>
      <button>Checkout</button>
    </div>
  );
};

export default BasketSummary;