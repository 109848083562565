import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import StashupLogo from "src/assets/icons/stashup-logo";
import { AuthGuard } from "src/components/Auth/AuthGuard";

function CheckoutLayout() {
  return (
    <AuthGuard>
      <div>
        <header className="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-52 2xl:px-96">
          <NavLink to="/items" className="text-xl font-bold text-gray-800 flex items-center">
            <StashupLogo fill="#D2AC47" strokeWidth={3} className="w-7 mr-2" />
            Stashup
          </NavLink>
          <div className="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
            <div className="relative">
              <ul className="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
                <li className="flex items-center space-x-3 text-left sm:space-x-4">
                  <a className="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700" href="#">
                    <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                  </a>
                  <span className="font-semibold text-gray-900">Basket</span>
                </li>
                <svg className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                </svg>
                <li className="flex items-center space-x-3 text-left sm:space-x-4">
                  <a className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2" href="#">2</a>
                  <span className="font-semibold text-gray-900">Payment</span>
                </li>
                <svg className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                </svg>
                <li className="flex items-center space-x-3 text-left sm:space-x-4">
                  <a className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400 text-xs font-semibold text-white" href="#">3</a>
                  <span className="font-semibold text-gray-500">Confirmation</span>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <main className="page-container">
          <Outlet />
        </main>
      </div>
    </AuthGuard>
  )
}

export default CheckoutLayout