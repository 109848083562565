import * as React from 'react';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Avatar, Sheet } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

function PersonalInfo() {
  const navigate = useNavigate();

  return (
    <Sheet
      onClick={() => navigate('/account-details')}
      className="card"
      component="li"
      variant="outlined"
      sx={{
        borderRadius: 'md',
        p: 2,
        listStyle: 'none',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Avatar variant="outlined" sx={{ width: '2.8rem', height: '2.8rem' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#D2AC47" className="bi bi-person-fill" viewBox="0 0 16 16">
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
          </svg>
        </Avatar>
        <Box>
          <Typography level="title-md">Account Details</Typography>
          <Typography level="body-sm">
            Track, return, cancel orders
          </Typography>
        </Box>
        <div className="ml-auto flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
          </svg>
        </div>
      </Box>

    </Sheet>
  )
}

export default PersonalInfo