import React from 'react';
import { CardMedia, Grid } from '@mui/material';
import { Box, Divider, Sheet, Avatar, Card, CardContent, Typography } from '@mui/joy';

const childrenData = [
  { name: 'Emma', age: 8, size: 'xs', avatar: '/static/images/avatars/avatar1.jpg' },
  { name: 'Oliver', age: 6, size: 'xs', avatar: '/static/images/avatars/avatar2.jpg' },
  { name: 'Sophia', age: 4, size: 'xs', avatar: '/static/images/avatars/avatar3.jpg' },
  // Add more children as needed
];

const Children = () => {
  return (
    <Sheet
      component="li"
      variant="outlined"
      sx={{
        borderRadius: 'sm',
        p: 2,
        listStyle: 'none',
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Avatar variant="outlined" sx={{ width: '2.8rem', height: '2.8rem' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#D2AC47" className="bi bi-people-fill" viewBox="0 0 16 16">
            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
          </svg>
        </Avatar>
        <Box>
          <Typography level="title-md">Your Children</Typography>
          <Typography level="body-sm">
            Track, return, cancel orders
          </Typography>
        </Box>
        <div className="ml-auto flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
          </svg>
        </div>
      </Box>
    </Sheet>
  );
};

export default Children;
