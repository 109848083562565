import React from 'react'
import './Wardrobe.scss'

type Props = {}

function Wardrobe({ }: Props) {
  return (
    <div className="wardrobe-page">
      <header className="flex justify-between items-start mb-5">
        <h1 className="text-3xl font-bold">My wardrobe</h1>
        <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition">
          + Add Item
        </button>
      </header>

      <div className="flex gap-4 mb-8">
        <select className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option>All Items</option>
          <option>Tops</option>
          <option>Bottoms</option>
          <option>Dresses</option>
          <option>Accessories</option>
        </select>
        <input type="text" placeholder="Search your wardrobe..." className="border border-gray-300 rounded px-3 py-2 flex-grow focus:outline-none focus:ring-2 focus:ring-blue-500" />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
        <div className="border border-gray-200 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition">
          <img src="item-image.jpg" alt="Clothing item" className="w-full h-48 object-cover" />
          <div className="p-4">
            <h3 className="font-semibold text-lg mb-1">Item Name</h3>
            <p className="text-gray-600 mb-4">Category</p>
            <div className="flex justify-between">
              <button className="bg-gray-100 px-3 py-1 rounded hover:bg-gray-200 transition">Edit</button>
              <button className="bg-gray-100 px-3 py-1 rounded hover:bg-gray-200 transition">Remove</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wardrobe