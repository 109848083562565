import React from 'react'

type Props = {}

function ShippingMethod({ }: Props) {
  return (
    <div>
      <p className="text-xl font-medium">Shipping Method</p>
      <p className="text-gray-400">Complete your order by providing your payment details.</p>
      <div className="relative my-4">
        <input className="peer hidden" id="radio_1" type="radio" name="radio" checked />
        <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
        <label className="peer-checked:border-1 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4" htmlFor="radio_1">
          <div className="w-14 h-14 flex justify-center items-center">
            <img className="w-14 object-contain" src="https://www.heckgrammar.co.uk/wp-content/themes/heckgrammar/assets/dist/grunticon/png/logo.png" alt="" />
          </div>
          <div className="ml-5">
            <span className="mt-2 font-semibold">Collection</span>
            <p className="text-slate-500 text-sm leading-6">Heckmondwike</p>
          </div>
        </label>
      </div>
      <div className="relative unavailable mb-8" style={{
        pointerEvents: 'none',
        opacity: 0.5
      }}>
        <input className="peer hidden" id="radio_2" type="radio" name="radio" />
        <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
        <label className="peer-checked:border-1 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4" htmlFor="radio_2">
          <div className="w-14 h-14 flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-truck w-10 h-10" viewBox="0 0 16 16">
              <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
            </svg>
          </div>
          <div className="ml-5">
            <span className="mt-2 font-semibold">Fedex Delivery</span>
            <p className="text-slate-500 text-sm leading-6">Unavailable</p>
          </div>
        </label>
      </div>
    </div>
  )
}

export default ShippingMethod