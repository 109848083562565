import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getProducts } from 'src/common/api/product'
import { Product } from 'src/common/types'
import { FilterSidebar, ProductCard } from 'src/components'
import MobileFilter from './components/MobileFilter'
import SidebarFilter from './components/SidebarFilter'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import FilterBar from './components/FilterBar'

type FormattedProduct = {
  id: string
  schoolId: string
  name: string
  price: string
  description: string
  image: string
}

function Items() {
  const { schoolId, search, } = useParams()
  const [products, setProducts] = useState<FormattedProduct[]>([])
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  const sortOptions = [
    { name: 'Most Popular', href: '#', current: true },
    { name: 'Best Rating', href: '#', current: false },
    { name: 'Newest', href: '#', current: false },
    { name: 'Price: Low to High', href: '#', current: false },
    { name: 'Price: High to Low', href: '#', current: false },
  ]
  const subCategories = [
    { name: 'Totes', href: '#' },
    { name: 'Backpacks', href: '#' },
    { name: 'Travel Bags', href: '#' },
    { name: 'Hip Bags', href: '#' },
    { name: 'Laptop Sleeves', href: '#' },
  ]
  const filters = [
    {
      id: 'color',
      name: 'Color',
      options: [
        { value: 'white', label: 'White', checked: false },
        { value: 'beige', label: 'Beige', checked: false },
        { value: 'blue', label: 'Blue', checked: true },
        { value: 'brown', label: 'Brown', checked: false },
        { value: 'green', label: 'Green', checked: false },
        { value: 'purple', label: 'Purple', checked: false },
      ],
    },
    {
      id: 'category',
      name: 'Category',
      options: [
        { value: 'new-arrivals', label: 'New Arrivals', checked: false },
        { value: 'sale', label: 'Sale', checked: false },
        { value: 'travel', label: 'Travel', checked: true },
        { value: 'organization', label: 'Organization', checked: false },
        { value: 'accessories', label: 'Accessories', checked: false },
      ],
    },
    {
      id: 'size',
      name: 'Size',
      options: [
        { value: '2l', label: '2L', checked: false },
        { value: '6l', label: '6L', checked: false },
        { value: '12l', label: '12L', checked: false },
        { value: '18l', label: '18L', checked: false },
        { value: '20l', label: '20L', checked: false },
        { value: '40l', label: '40L', checked: true },
      ],
    },
  ]

  const fetchProducts = async (schoolId?: string) => {
    try {
      const { response } = await getProducts(schoolId)
      const formattedProducts = response.map((product: any) => {
        return {
          id: product.id,
          schoolId: product.school_id,
          name: product.name,
          price: product.price,
          description: product.description,
          image: product.images[0].image_url,
        }
      })
      setProducts(formattedProducts)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchProducts(schoolId)
  }, [schoolId])

  return (
    <div>
      <MobileFilter {...{ mobileFiltersOpen, setMobileFiltersOpen, filters, subCategories }} />
      <main>
        <FilterBar {...{ setMobileFiltersOpen, sortOptions }} />
        <section aria-labelledby="products-heading" className="pb-24 pt-6">
          <h2 id="products-heading" className="sr-only">
            Products
          </h2>
          <div className="flex gap-12">
            <SidebarFilter {...{ filters, subCategories }} />
            <div className='grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-12 lg:w-11/12'>
              {products.map(product => (
                <ProductCard key={product.id} {...product} />
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default Items