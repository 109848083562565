import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { RootState } from 'src/store'
import { setBasketIsOpen } from 'src/store/slices/basketSlice'

type Props = {}

function Basket({ }: Props) {
  const basket = useSelector((state: RootState) => state.basket)
  const { isOpen } = useSelector((state: RootState) => state.basket);
  const dispatch = useDispatch();

  const setOpen = (state: boolean) => {
    dispatch(setBasketIsOpen(state));
  }

  return (
    <button onClick={() => setOpen(true)} className="basket-button">
      <svg xmlns="http://www.w3.org/2000/svg" width="1.1rem" viewBox="0 0 16 16">
        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4z" />
      </svg>
      {basket.basketItems.length > 0 && (
        <span>{basket.basketItems.length}</span>
      )}
    </button>
  )
}

export default Basket