import { Product } from '../types'
import { Request } from './api'

const RequestConfig = {
  get_products: (schoolId?: string) => {
    return {
      method: 'GET',
      url: `/api/product${schoolId ? `?schoolId=${schoolId}` : ''}`,
      contentType: 'application/json',
      e: 'Cannot send password reset email',
    }
  },
  get_product_by_id: (id: string) => {
    return {
      method: 'GET',
      url: `/api/product/${id}`,
      contentType: 'application/json',
      e: 'Cannot send password reset email',
    }
  },
}

const getProducts = async (schoolId?: string): Promise<{
  response: Product[]
  responseStatusCode: number | undefined
}> => {
  return Request(RequestConfig.get_products(schoolId))
}

const getProductById = async (id: string): Promise<{
  response: Product
  responseStatusCode: number | undefined
}> => {
  return Request(RequestConfig.get_product_by_id(id))
}

export { getProducts, getProductById }