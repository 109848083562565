import { Avatar, Button, Card, CardContent, Divider, Sheet, Typography } from '@mui/joy'
import { Box, CardMedia, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'src/contexts/AuthContext'

type Props = {}

function Wardrobe({ }: Props) {
  const navigate = useNavigate()

  return (
    <Sheet
      className="card"
      component="li"
      variant="outlined"
      sx={{
        borderRadius: 'sm',
        p: 2,
        listStyle: 'none',
      }}
      onClick={() => navigate('/wardrobe')}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Avatar variant="outlined" sx={{ width: '2.8rem', height: '2.8rem' }}>
          <svg fill="#D2AC47" height="22" width="22" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 295.526 295.526">
            <g>
              <path d="M147.763,44.074c12.801,0,23.858-8.162,27.83-20.169c-7.578,2.086-17.237,3.345-27.83,3.345   c-10.592,0-20.251-1.259-27.828-3.345C123.905,35.911,134.961,44.074,147.763,44.074z" />
              <path d="M295.158,58.839c-0.608-1.706-1.873-3.109-3.521-3.873l-56.343-26.01c-11.985-4.06-24.195-7.267-36.524-9.611   c-0.434-0.085-0.866-0.126-1.292-0.126c-3.052,0-5.785,2.107-6.465,5.197c-4.502,19.82-22.047,34.659-43.251,34.659   c-21.203,0-38.749-14.838-43.25-34.659c-0.688-3.09-3.416-5.197-6.466-5.197c-0.426,0-0.858,0.041-1.292,0.126   c-12.328,2.344-24.538,5.551-36.542,9.611L3.889,54.965c-1.658,0.764-2.932,2.167-3.511,3.873   c-0.599,1.726-0.491,3.589,0.353,5.217l24.46,48.272c1.145,2.291,3.474,3.666,5.938,3.666c0.636,0,1.281-0.092,1.917-0.283   l27.167-8.052v161.97c0,3.678,3.001,6.678,6.689,6.678h161.723c3.678,0,6.67-3.001,6.67-6.678V107.66l27.186,8.052   c0.636,0.191,1.28,0.283,1.915,0.283c2.459,0,4.779-1.375,5.94-3.666l24.469-48.272C295.629,62.428,295.747,60.565,295.158,58.839z   " />
            </g>
          </svg>
        </Avatar>
        <Box>
          <Typography level="title-md">Your Wardrobe</Typography>
          <Typography level="body-sm">
            Track, return, cancel orders
          </Typography>
        </Box>
        <div className="ml-auto flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
          </svg>
        </div>
      </Box>
    </Sheet>
  )
}

export default Wardrobe
