import * as React from 'react';
import StashupLogo from 'src/assets/icons/stashup-logo';
import signInImage from 'src/assets/images/sign-in-image.png';

export interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps): React.JSX.Element {
  return (
    <div className='signIn-background'>
      <div className='signIn-container'>
        <div className='signIn-image'>
          <div className='signIn-logo image-container'>
            <StashupLogo fill='#023047' strokeWidth={3} />
          </div>
          <div className='image-container inside' style={{ backgroundImage: `url(${signInImage})` }}></div>
        </div>
        {children}
      </div>
    </div>
  );
}
