import React, { useEffect, useState } from 'react'
import OrderSummary from './components/OrderSummary'
import PaymentForm from './components//PaymentForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import ShippingMethod from './components/ShippingMethod';
import { createSetupIntent } from 'src/common/api';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const stripePromise = loadStripe('pk_live_51PXmw0RwtexRxiHexxEPBQI96ZR9fyFXRt4ggwbcyZyfyc3gIPVFYuu1CQyNexqf7ZOGFvBzBi9c6WraOTY1YJOB00K1t36pip');

type Props = {}

function Checkout({ }: Props) {
  const { basketItems } = useSelector((state: RootState) => state.basket);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const basketItemIds = basketItems.map((item) => item.id);

  const fetchClientSecret = async () => {
    const {response, responseStatusCode} = await createSetupIntent(basketItemIds)
    console.log(response)
    if (responseStatusCode !== 200) return console.error(response)
    setClientSecret(response.clientSecret)
  }

  useEffect(() => {
    fetchClientSecret()
  }, []);

  return (
    <div className="checkout-page">
      <div className="grid lg:grid-cols-2">
        <div className="mt-10 bg-gray-50 px-12 pt-8 lg:mt-0 sm:pl-10 lg:pl-20 xl:pl-52 2xl:pl-96 min-h-screen h-min">
          {clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PaymentForm />
            </Elements>
          )}
        </div>
        <div className="flex flex-col gap-8 px-12 pt-8 sm:pr-10 lg:pr-20 xl:pr-52 2xl:pr-96">
          <OrderSummary />
          <ShippingMethod />
        </div>
      </div>
    </div>
  )
}

export default Checkout