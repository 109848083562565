import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasketItemType } from '../../common/types';

interface BasketState {
  basketItems: BasketItemType[];
  isOpen?: boolean;
}

const initialBasketState: BasketState = {
  basketItems: [],
  isOpen: false,
};

const basketSlice = createSlice({
  name: 'bookings',
  initialState: initialBasketState,
  reducers: {
    setBasket: (state, action: PayloadAction<BasketItemType[]>) => {
      state.basketItems = action.payload;
    },
    addToBasket: (state, action: PayloadAction<BasketItemType>) => {
      state.basketItems.push(action.payload);
    },
    setBasketIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  }
});

export const { setBasket, addToBasket, setBasketIsOpen } = basketSlice.actions;
export const selectBasketItems = (state: { basketItems: BasketState }) => state.basketItems.basketItems;

export default basketSlice.reducer;
