import React from 'react'
import { NavLink } from 'react-router-dom'
import { BasketItemType } from 'src/common/types'

type Props = {
  basketItem: BasketItemType
  handleRemoveItem: (id: string) => () => void
}

function BasketItem({ basketItem, handleRemoveItem }: Props) {
  return (
    <li key={basketItem.id} className="flex py-6">
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <img
          src={basketItem.imageSrc}
          alt={basketItem.imageAlt}
          className="h-full w-full object-contain object-center"
          style={{backgroundColor: "#f7f7f7"}}
        />
      </div>

      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <h3>
              <NavLink to={`/item/${basketItem.id}`} className="hover:underline">
                {basketItem.name}
              </NavLink>
            </h3>
            <p className="ml-4">£{basketItem.price}</p>
          </div>
          <p className="mt-1 text-sm text-gray-500">{basketItem.size}</p>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          <p className="text-gray-500">Qty {basketItem.quantity}</p>

          <div className="flex">
            <button onClick={handleRemoveItem(basketItem.id)} type="button" className="font-medium text-indigo-600 hover:text-indigo-500">
              Remove
            </button>
          </div>
        </div>
      </div>
    </li>
  )
}

export default BasketItem