import React from "react";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "src/components/Auth/AuthGuard";
import { Footer, Header } from "src/components";
import { navigationLinks } from "src/constants/navigation";
import BasketSidebar from "src/components/BasketSidebar/BasketSidebar";
import './RootLayout.scss';

function RootLayout() {
  return (
    <AuthGuard>
      <div id="root-layout" className="flex">
        <Header {...{navigationLinks, }} />
        <main className="page-container">
          <Outlet />
        </main>
        <BasketSidebar />
        <Footer />
      </div>
    </AuthGuard>
  )
}

export default RootLayout