import React from 'react'
import './ItemSizes.scss'

type Props = {
  itemId: string | undefined
  handleSizeChange: (e: React.MouseEvent<HTMLButtonElement>) => void
}

function ItemSizes({ itemId, handleSizeChange }: Props) {
  const sizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL']
  const [selectedSize , setSelectedSize] = React.useState('' as string)

  const handleSizeClick = (e: React.MouseEvent<HTMLButtonElement>, size: string) => {
    setSelectedSize(size)
    handleSizeChange(e)
  }

  return (
    <div className="item-sizes">
      <span>Size</span>
      <div>
        {sizes.map((size, index) => (
          <button
            dataset-size={size}
            key={index}
            onClick={(e) => handleSizeClick(e, size)}
            className={`size-button${selectedSize === size ? ' active' : ''}`}
          >
            {size}
          </button>
        ))}
        <a href="#">Size guide</a>
      </div>
    </div>
  )
}

export default ItemSizes