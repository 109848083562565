import * as React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { AccountDetails, Orders, Wardrobe, Children } from './components';
import './Account.scss';

export default function Account() {
  return (
    <div className="account-page">
      <Box sx={{ flex: 1, width: '70%', margin: 'auto' }}>
        <Box
          sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
          }}
        >
        <h1 className="text-3xl font-bold mb-5">My account</h1>
        </Box>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <AccountDetails />
          <Orders />
          <Wardrobe />
          <Children />
          <Children />
          <Children />
        </div>
      </Box>
    </div>
  );
}
