import React, { useState } from 'react'
import { ProductImage } from 'src/common/types'

type Props = {
  images: ProductImage[]
}

function ImageCarousel({ images }: Props) {
  const [activeImg, setActiveImage] = useState<number>(0)

  return (
    <div className='image-carousel-container lg:w-2/4'>
      <div className='thumbnail-container'>
        {images?.map((image, index) => (
          <img
            key={index}
            src={image.image_url}
            alt={image.alt_text}
            onClick={() => setActiveImage(index)}
          />
        ))}
      </div>
      <img src={images[activeImg].image_url} alt="" />
    </div>
  )
}

export default ImageCarousel