import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

type Props = {}

function OrderSummary({ }: Props) {
  const { basketItems } = useSelector((state: RootState) => state.basket);

  return (
    <div>
      <p className="text-xl font-medium">Order Summary</p>
      <p className="text-gray-400">Check your items. And select a suitable shipping method.</p>
      <div className="mt-4 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6">
        {basketItems.map((item) => (
          <div className="flex flex-col rounded-lg bg-white sm:flex-row">
            <img className="m-2 h-24 w-28 rounded-md border object-contain" src={item.imageSrc} alt="" style={{ backgroundColor: "#f7f7f7" }} />
            <div className="flex w-full flex-col px-4 py-4">
              <span className="font-semibold">{item.name}</span>
              <span className="float-right text-gray-400">42EU - 8.5US</span>
              <p className="text-lg font-bold">£{item.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OrderSummary