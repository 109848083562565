import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './layouts/RootLayout';
import Page from './pages/auth/sign-in/page';
import { Item, Items, Basket, Account, AccountDetails, Checkout, Wardrobe } from './pages';
import './App.scss';
import { Orders } from './pages';
import CheckoutLayout from './layouts/CheckoutLayout/CheckoutLayout';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          path: "*",
          element: <div>404</div>,
        },
        {
          path: "/account",
          element: <Account />,
        },
        {
          path: "/account-details",
          element: <AccountDetails />,
        },
        {
          path: "/orders",
          element: <Orders />,
        },
        {
          path: "/wardrobe",
          element: <Wardrobe />,
        },
        {
          path: "/dashboard",
          element: <div>home</div>,
        },
        {
          path: "/schools",
          element: <div>Schools</div>,
        },
        {
          path: "/schools/:schoolId",
          element: <div>School</div>,
        },
        {
          path: "/items/:schoolId?",
          element: <Items />,
        },
        {
          path: "/item/:schoolId/:itemId",
          element: <Item />,
        },
        {
          path: "/basket",
          element: <Basket />,
        },
      ],
    },
    {
      path: "/checkout",
      element: <CheckoutLayout />,
      children: [
        {
          path: "/checkout",
          element: <Checkout />,
        },
        {
          path: "/checkout/confirmation",
          element: <Checkout />,
        },
      ],
    },
    {
      path: '/auth',
      children: [
        {
          path: 'sign-in',
          element: <Page />,
        },
        {
          path: 'sign-up',
          element: <div>sign up</div>,
        },
      ],
    },
  ])

  return (
    <RouterProvider router={router}></RouterProvider>
  );
}

export default App;
